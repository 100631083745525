.processing {
    height: calc(100vh - 75px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.processingIcon {
    vertical-align: middle;
    margin-right: 10px;
}
