.left {
    float: left;
    width: 330px;
    /*height: 470px;*/
    padding: 20px;
    background: rgba(191, 191, 191, 0.15);
    margin-right: 30px;
}

.right {
    overflow: hidden;
}


.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected {
    background-color: rgba(51,122,183,.7);
}

.cell-status {
    padding: -7px;
}