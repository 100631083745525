.logo {
    float: left;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
}

.logoTitle {
    float: left;
    font-size: 1.25rem;
    width: 250px;
}

.header {
    background: #ffffff !important;
}
