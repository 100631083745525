.action-button {
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
    display: grid;
    grid-template-rows: 37px auto;
    align-content:center center;
    gap: 2px;
}


img {
    align-self: center;
    justify-self: center;
}

.action-button-checked {
    font-weight: bold;
}