.tab {
    margin-right: 10px;
}

.tab-notselected {
    cursor: pointer;
}

.tab-selected {
    font-weight: bold;
}

.tabs {
    display: flex;
    flex-direction: row;
}