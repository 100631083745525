/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

body {
    padding-top: 0px;
    padding-bottom: 0px;
}

/* Set padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

.content {
    padding: 10px 10px 0;
}

.ant-table-body {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}

.ant-table-body:hover {
    overflow-y: auto !important;
    overflow-x: auto !important;
}

.ant-layout {
    background-color: transparent !important;
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: none !important;
}

.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.85) !important;
}

.ant-form-item {
    margin: 0 0 4px !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #000 !important;
}

.ant-layout-header {
    padding: 0 !important;
}
