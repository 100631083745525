#DivMenuCad {
    position: absolute;
    margin-left: 0;
    width: 30px;
    /*background: #008000;*/
    margin: 0;
    padding: 0;
}

#DivCad {
    position: relative;
    left: 30px;
    /*background: #e0e0e0;*/
    /*margin-left: 50px;*/
    margin: 0;
    padding: 0;
}

.CadButton {
    width: 30px;
    height: 30px;
    position: relative;
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
}

/*#DivCad-SplitVert:enabled {
    background-image: url('images/cad-split-vert.png');
}

#DivCad-SplitVert:disabled {
    background-image: url('images/cad-split-vert-bw.png');
}

#DivCad-SplitHor:enabled {
    background-image: url('images/cad-split-hor.png');
}

#DivCad-SplitHor:disabled {
    background-image: url('images/cad-split-hor-bw.png');
}

#DivCad-Del:enabled {
    background-image: url('images/cad-del.png');
}

#DivCad-Del:disabled {
    background-image: url('images/cad-del-bw.png');
}

#DivCad-AlignGeom:enabled {
    background-image: url('images/cad-allign-geom.png');
}

#DivCad-AlignGeom:disabled {
    background-image: url('images/cad-allign-geom-bw.png');
}

#DivCad-AlignLight:enabled {
    background-image: url('images/cad-allign-ligth.png');
}

#DivCad-AlignLight:disabled {
    background-image: url('images/cad-allign-ligth-bw.png');
}

#DivCad-Undo:enabled {
    background-image: url('images/cad-undo.png');
}

#DivCad-Undo:disabled {
    background-image: url('images/cad-undo-bw.png');
}

#DivCad-ElemInfo:enabled {
    background-image: url('images/cad-elem-info.png');
}

#DivCad-ElemInfo:disabled {
    background-image: url('images/cad-elem-info-bw.png');
}

#DivCad-Mosk:enabled {
    background-image: url('images/cad-mosk.png');
}

#DivCad-Mosk:disabled {
    background-image: url('images/cad-mosk-bw.png');
}

#DivCad-StvIns:enabled {
    background-image: url('images/cad-stv-ins.png');
}

#DivCad-StvIns:disabled {
    background-image: url('images/cad-stv-ins-bw.png');
}

#DivCad-StvInfo:enabled {
    background-image: url('images/cad-stv-info.png');
}

#DivCad-StvInfo:disabled {
    background-image: url('images/cad-stv-info-bw.png');
}

#DivCad-SysChange:enabled {
    background-image: url('images/cad-sys-change.png');
}

#DivCad-SysChange:disabled {
    background-image: url('images/cad-sys-change-bw.png');
}

#DivCad-AksIns:enabled {
    background-image: url('images/cad-aks-ins.png');
}

#DivCad-AksIns:disabled {
    background-image: url('images/cad-aks-ins-bw.png');
}*/