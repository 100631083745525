.order-izd:hover {
    background-color: #f5f5f5;
}

.dx-texteditor.dx-editor-outlined {
    border-color: #000000;
}

.izd-header {
    display: flex;
    justify-content: space-between;
}