.toolbal {
    padding-bottom: 10px;
}

.order-header{
    flex: 0 0 auto;
    display: flex;
}

.dotStatus {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
  }